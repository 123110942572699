/* Project Header */

#headerbox {
	margin: -6em 18em 5em 18em;
	overflow: hidden;
}

#headerbox .Card {
	padding: 1.25rem;
	background-color: rgba(255, 255, 255, .7);
	margin-bottom: 7.5em;
	overflow: hidden;
}

.headerbg {
	height: 400px;
}

.headerTitle {
	letter-spacing: 2px;
}

