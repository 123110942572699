/* ===========================
  Responsive
==============================*/

/* Large Display */

@media only screen and (min-width: 1600px) {
	html {
		font-size: 80% !important;
	}
	.container {
		width: 100% !important;
	}
	#headerbox {
		margin: -6em 40em 5em 40em !important;
	}
	.mb-intro {
		margin-bottom: 19em!important;
	}
	.display-4 {
		padding-top: 0.5em !important;
		font-size: 4.5em !important;
	}
	.headerbg {
		height: 57em !important;
	}
	.mb-intro .intro-container {
		padding: 2em 0 4em 1em;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 0em;
		height: 60em !important;
	}
	.col-md-6 {
		max-width: 48%!important;
	}
	span {
		word-spacing: 2px;
		/* text-indent: 3em; */
	}
	/* Parallax Header Image  */
	/* .parallax-mobile-view img {
		top: -4em !important;
		height: 102em !important;
	} */
	/* .display-4{
		padding-top: 0em !important;
	} */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-color: white;
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		border: solid white; 
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px 100px 30px;
	}	
}

@media only screen and (min-width: 1477px) and (max-width: 1599px) {
	html {
		font-size: 80%;
	}
	.container {
		max-width: 80%!important;
	}
	.view .mb-intro {
		margin-bottom: 12em;
	}
	.view .imgFit {
		height: 57vh !important;
		width: 93vw !important;
	}
	/* Parallax Header Image  */
	.display-4 {
		padding-top: 0.5em !important;
	}
	/* #headerbox{
		margin: -6em 25em 5em 25em !important;
	} */
	/* .container {
		max-width: 1200px !important;
	} */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-color: white;
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		border: solid white; 
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px 100px 30px;
	}	
}

@media only screen and (min-width: 1400px) and (max-width: 1476px) {
	html {
		font-size: 80%;
	}
	.container {
		max-width: 80%!important;
	}
	.view .imgFit {
		height: 57vh !important;
		width: 93vw !important;
	}
	.view .stripe div p {
		font-size: 1.25em !important;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 9em;
		height: 77em !important;
	}
	.display-4 {
		padding-top: 0.5em !important;
	}
	.view .mb-intro {
		margin-bottom: 10em;
	}
	#headerbox {
		margin: -6em 23em 5em 23em;
	}
	.headerbg-contact {
		height: 47em !important;
	}
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-color: white;
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		border: solid white; 
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px 100px 30px;
	}	
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
	html {
		font-size: 80%;
	}
	.container {
		width: 85% !important;
	}
	.view .stripe div p {
		font-size: 1.25em !important;
	}
	.display-4 {
		padding-top: 0.5em !important;
	}
	#headerbox {
		margin: -6em 20em 5em 20em;
	}
	.view .mb-intro {
		margin-bottom: 10em !important;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 14em !important;
		height: 70em !important;
	}
	.headerbg-contact {
		height: 43em;
	}
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-color: white;		
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		border: solid white; 
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px 100px 30px;
	}

}

/* Medium screen : 992px. Ipad Pro */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	html {
		font-size: 80% !important;
	}
	.container {
		width: 95% !important;
	}
	.view .imgFit {
		height: 22vh !important;
		width: 93vw !important;
	}
	.view .stripe {
		padding: 0.2rem !important;
		font-size: 0.8em !important;
	}
	#headerbox {
		margin: -6em 8em 5em 8em !important;
	}
	#headerbox .Card {
		margin-bottom: 6em !important;
	}
	.mb-intro .intro-container {
		padding: 0em 0 2em 0em;
	}
	.mb-intro {
		margin-bottom: 11em!important;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 15em !important;
		height: 58em !important;
	}
	.headerbg-contact {
		height: 62em!important;
	}
	.display-4 {
		padding-top: 1em !important;
	}
	.contactcard_margin {
		margin-top: -22em!important
	}
	/* .btn {
		font-size: 0.5em !important;
	} */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		border: solid white; 
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px 100px 30px;
	}
}

/* Tablet :768px. Ipad */

@media only screen and (min-width: 768px) and (max-width: 991px) {
	html {
		font-size: 80% !important;
	}
	.container {
		width: 100% !important;
	}
	#navigation .navbar:not(.top-nav-collapse) {
		background: white !important;
	}
	#btngroup {
		position: relative;
		display: grid;
		vertical-align: middle;
	}
	#headerbox {
		margin: -7em 9em 5em 9em;
	}
	.view .imgFit {
		height: 38vh !important;
		width: 93vw !important;
		object-position: center !important;
	}
	.display-4 {
		font-size: 3.25em !important;
		padding-top: 1em !important;
	}
	.display-5 {
		font-size: 1.25em !important;
	}
	.mb-intro .intro-container {
		padding: 0em 0 1em 0em;
		margin: 0 2em 0 2em;
	}
	.mb-intro {
		margin-bottom: 11em!important;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 18em !important;
		height: 50em !important;
	}
	.contactcard_margin {
		margin-top: -22em!important
	}
	.contactcard_width {
		width: 50% !important;
	}
	.contactcard_padding_mobile {
		padding-left: 0px !important;
	}
	.headerbg-contact {
		height: 42em;
	}
	/* .btn {
		font-size: 0.75em !important;
	} */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px .5rem 30px;
	}
}

@media only screen and (min-width: 520px) and (max-width: 767px) {
	html {
		font-size: 80% !important;
	}
	.container {
		width: 100% !important;
	}
	.display-4 {
		font-size: 4em !important;
		padding-top: 0em !important;
	}
	.mb-intro .intro-container {
		padding: 2em 0 2em 0em;
	}
	.card-margin {
		width: 50% !important;
		margin-bottom: 1em !important;
	}
	.contactcard_width {
		width: 50% !important;
	}
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 1px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px .5rem 30px;
	}
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
	.container {
		width: 100% !important;
	}
	/* For BayBridge Photo */
	/* #carouselIntro .view {
		background-image: url('../../images/Backgrounds/Carousel-1-mobile.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	} */
	#headerbox {
		margin: -7em 2em 5em 2em;
	}
	.headerbg {
		height: 300px;
	}
	.headerTitle {
		font-size: 2.5em !important;
		/* letter-spacing: 2px; */
	}
	/* p, h3 {
		letter-spacing: 1px;
	} */
	.headerDate {
		font-size: 1em !important;
	}
	.headerDesc {
		font-size: 1em !important;
	}
	.container p {
		text-indent: 0em;
	}
	.display-3 {
		font-size: 1.5em !important;
		margin-top: 1em !important;
	}
	.subtext-header {
		font-size: 1em !important;
	}
	/* All Project Card Size */
	.view .imgFit {
		height: 27em !important;
		/* width: 32em !important; */
		object-fit: cover !important;
		object-position: center !important;
	}
	.section .project-margins {
		margin: 1em !important;
	}
	.view .imgFit {
		/* height:25vh !important; */
		height: 27vh !important;
		width: 100vw !important;
		object-position: center !important;
	}
	.project-margins {
		margin: -3em 1em 1em 1em !important;
	}
	.view .stripe {
		bottom: 1rem !important;
		padding: 0.2rem !important;
	}
	.view .stripe p {
		font-size: 0.8em !important;
	}
	.display-4 {
		font-size: 4em !important;
		padding-top: 0em !important;
	}
	.display-5 {
		font-size: 1em !important;
	}
	.display-6 {
		font-size: 0.85em !important;
	}
	.mb-5 {
		margin-bottom: 1em !important;
	}
	/* Parallax Header Image  */
	.parallax-mobile-view img {
		top: 0em !important;
		height: 37em !important;
		/* content: url('../../images/mobile_header.jpg') !important; */
	}
	/* All Projects Image Cover for mobile */
	/* .ap-mobile-cover  {
		content: url('../../images/About_Page/about.jpg') !important;
	} */
	/*.ap-mobile-cover-Lauren_Lychee {
		content: url('../../public/images/DSC03419.jpg') !important;
	}
	.ap-mobile-cover-Lilly_Linkedin {
		content: url('../../public/images/DSC03419.jpg') !important;
	}
	.ap-mobile-cover-Ninasky {
		content: url('../../public/images/DSC03419.jpg') !important;
	}
	.featured-header-mobile img {
		content: url('../../public/images/DSC03419.jpg') !important; 
		top: 14em !important;
		height: 50em !important;
	}*/
	.contactcard_width {
		width: 50% !important;
	}
	.col-md-6 {
		padding-left: 0.5em !important;
		padding-right: 0.5em !important;
	}
	.view .mb-intro {
		margin-bottom: 12em !important;
	}
	.headerbg-contact {
		height: 39em!important;
	}
	/* .btn {
		font-size: 0.5em !important;
	} */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 0px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px .5rem 30px;
	}
}

/* Iphone X */

@media only screen and (max-width: 375px) and (max-height: 812px) {
	.container {
		width: 100% !important;
	}
	.contactcard_margin {
		margin-top: -37em!important;
		/* margin-bottom: 16em !important; */
	}
	.parallax-mobile-view img {
		top: 8em !important;
		height: 48em !important;
	}
	#carouselIntro .view {
		background-position: 72% !important;
	}
	.view .mb-intro {
		margin-bottom: 10em !important;
	}
	.social-media {
		margin-left: -1.25em !important;
	}
	.headerbg-contact {
		height: 39em!important;
	}
	.tech-info {
		font-size: 1.5rem !important;
	}
	/* .fa {
	padding: .5rem;
    font-size: 1em !important;
    width: 1.7em;
    text-align: center;
    text-decoration: none;
    margin: 1px;
    border-radius: 25%;
  } */
	/* .btn-lg{
	padding: .25rem 0rem .5rem 1rem !important;
  } */
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 0px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px .5rem 30px;
	}
}

/* small mobile :320px. */

@media only screen and (max-width: 479px) {
	html {
		font-size: 100% !important;
	}
	.headerbg {
		height: 250px;
	}
	.view .stripe {
		bottom: 1rem !important;
		padding: 0.2rem !important;
	}
	.button.down {
		width: 3.5em;
		height: 3.5em;
		margin-bottom: 3.5em;
	}
	#headerbox {
		margin-top: -5em !important;
	}
	#headerbox .Card {
		padding: 0.5em !important;
		/* margin-bottom: 6em !important; */
	}
	.rwt__tab {
		padding: 0.5rem 0.75rem !important;
	}
	.card-margin {
		margin: 1em !important;
	}
	.display-4 {
		font-size: 1.85em !important;
		padding-top: 1em !important;
	}
	.display-5 {
		font-size: 1em !important;
	}
	.intro-title {
		font-size: 3em !important;
		/* padding-top: 0.5em !important; */
	}
	.intro-subtitle {
		font-size: 1em !important;
		/* padding-top: 0.5em !important; */
	}
	.mb-intro .intro-container {
		padding: 0em 0 2em 0em;
	}
	.col-md-6 {
		padding-left: 0em !important;
		padding-right: 0em !important;
	}
	btn {
		font-size: .75em !important;
	}
	.contactcard_margin {
		margin-top: -20em!important
	}
	.contactcard_info {
		font-size: 0.80em !important;
	}
	.contactcard_title {
		font-size: 1.2em !important;
	}
	.contactcard_padding {
		padding-left: 0px !important;
	}
	.page-footer {
		margin-top: 8em !important;
	}
	.pagefooter-margin {
		margin-top: -3.5rem!important;
		padding-top: 1.5rem!important;
	}
	.social-media .fa {
		font-size: 1.25em !important;
	}
	.btn-lg {
		padding: .25rem 0px .5rem 1em !important;
	}
    .about {
        padding-left:'13%'; 
        padding-right:'13%'; 
        padding-top:'15px'; 
        padding-bottom:'40px'; 
        display: 'flex'
    }
	.centerpiece {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 0px;
		overflow: hidden;
	}

	.centerpiece .businesscard {
		background-color: rgba(39, 21, 21, 0.12); 
		padding: 50px 400px .5rem 30px;
	}
}



/* Intro Arrow Button */

.button, buttonMore {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
	-moz-transition: background-color 0.2s ease-in-out;
	-webkit-transition: background-color 0.2s ease-in-out;
	-ms-transition: background-color 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out;
	background-color: rgba(229, 230, 231, 0.375);
	border: 0;
	border-radius: 3.5em;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	height: 3.5em;
	line-height: 3.5em;
	outline: 0;
	padding: 0 2em 0 2em;
	position: relative;
	text-align: center;
	text-decoration: none;
	overflow: hidden;
}

input[type="button"].down, .button.down, button.down {
	width: 5em;
	height: 5em;
	line-height: 4.5em;
	padding: 0;
	/*background-image: url("../../down-arrow.png");*/
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: -10em;
	overflow: hidden;
	margin-bottom: 5em;
}

button:hover {
	background-color: rgba(229, 230, 231, 0.375);
}

.button:active, button:active {
	background-color: rgba(229, 230, 231, 0.375);
}

.button.style2, button.style2 {
	/* background-color: transparent; */
	background-color: rgba(39, 21, 21, 0.12);
	border: solid 1px #f8f9fa;
	color: inherit;
}

.button.style2:hover, button.style2:hover {
	background-color: rgba(229, 230, 231, 0.375);
}

.button.style2:active, button.style2:active {
	background-color: rgba(229, 230, 231, 0.375);
}


.center {
	text-align: center;
}

.aboutme {
	padding: 15px 18% 18% 18%;
}

.hrspacer {
	margin-top: .2em;
	margin-bottom: .2em;
}


/* Gallery Container View */

#GalleryContainer h1 {
	margin-bottom: .4em;
}

/* Margin of Masonry Grid */

#GalleryContainer {
	/* margin: 0.5em; */
	margin-top: -5em;
}

.GalleryContainer {
	margin: '-5em 0em 2em 0em' !important;
}

#logo {
	text-decoration: none;
}

.zoom{
	transition: transform ease-in-out;
	overflow: hidden;
}
.zoom img{

	transition: transform .5s ease;
}

.zoom:hover img{
	transform: scale(1.2);
}

